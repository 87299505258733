<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            getHelperObject('common_area'),
            helpers.identifier,
            { ...helpers.datetimeDate, key: 'min_date', label: 'Starts', helper: { ...helpers.datetimeDate.helper, id: 'min_date' }, render: datetime => this.$m(datetime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD h:mm a') },
            { ...helpers.datetimeDate, key: 'max_date', label: 'Ends', helper: { ...helpers.datetimeDate.helper, id: 'max_date' }, render: datetime => this.$m(datetime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD h:mm a') },
            { key: 'concurrency', label: 'People' }
          ]"
          :config="{
            url: 'common_area_reservations',
            route: '/tools/common_area_reservations',
            name: 'common_area_reservation',
            params: { _lists: 'common_areas', building_id: this.building_id || 0, ...(params || {}) },
            noCreate: true,
            edit: false
          }"
          @preparedHelpers="onPreparedHelpers"
        >
          <template #edit="{item}">
            <CButton color="primary" class="mr-2" @click="editItem(item)">Edit</CButton>
          </template>
          <template #header-button>
            <CButton class="float-right" color="primary" @click="createItem">{{ $t(`Create common area reservation`) }}</CButton>
          </template>
        </ACRUDTable>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'CommonAreaReservations',
  mixins: [crudMixin],
  props: ['building_id', 'params'],
  watch: {
    building_id(new_value, old_value) {
      console.log('watch', new_value)
      if (new_value && new_value !== old_value) this.onGetData(new_value)
    }
  },
  methods: {
    onGetData(building_id) {
      // if (month_ids) {
      // this.month = { month_id, _period_name: this.getPeriodName(month_id) }
      this.$refs.crud_table.getData(false, { building_id }, true)
      // }
    },
    onDataChanged() {
      this.checked = this.$refs.crud_table.getChecked()
    },
    editItem(event) {
      console.log(event, this.building_id)
      this.$store.dispatch('setHeaderBuildingId', this.building_id)
      if (this.$moment.utc().isBefore(event.min_date)) {
        //this.$store.dispatch('setCurrentItems', { name: 'common_area_reservations', items: this.table_items })
        this.$router.push({ path: `/tools/common_area_reservations/${event.id}/edit`, props: { building_id: this.building_id }, params: { building_id: this.building_id } })
      } else {
        this.showAlert('Este evento ya pasó', 'warning')
        this.$router.push({ path: `/tools/common_area_reservations/${event.id}` /*, props: { building_id: this.building_id }, params: { building_id: this.building_id }*/ })
      }
    },
    createItem() {
      this.$store.dispatch('setHeaderBuildingId', this.building_id)
      //this.$store.dispatch('setCurrentItems', { name: 'common_area_reservations', items: this.table_items })
      this.$router.push({ path: `/tools/common_area_reservations/create`, props: { building_id: this.building_id }, params: { building_id: this.building_id } })
    }
  }
}
</script>
