var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          //'id',
          _vm.getHelperObject('common_area'),
          _vm.helpers.identifier,
          Object.assign({}, _vm.helpers.datetimeDate, {key: 'min_date', label: 'Starts', helper: Object.assign({}, _vm.helpers.datetimeDate.helper, {id: 'min_date'}), render: function (datetime) { return this$1.$m(datetime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD h:mm a'); }}),
          Object.assign({}, _vm.helpers.datetimeDate, {key: 'max_date', label: 'Ends', helper: Object.assign({}, _vm.helpers.datetimeDate.helper, {id: 'max_date'}), render: function (datetime) { return this$1.$m(datetime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD h:mm a'); }}),
          { key: 'concurrency', label: 'People' }
        ],"config":{
          url: 'common_area_reservations',
          route: '/tools/common_area_reservations',
          name: 'common_area_reservation',
          params: Object.assign({}, {_lists: 'common_areas', building_id: this.building_id || 0}, (_vm.params || {})),
          noCreate: true,
          edit: false
        }},on:{"preparedHelpers":_vm.onPreparedHelpers},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
        var item = ref.item;
return [_c('CButton',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("Edit")])]}},{key:"header-button",fn:function(){return [_c('CButton',{staticClass:"float-right",attrs:{"color":"primary"},on:{"click":_vm.createItem}},[_vm._v(_vm._s(_vm.$t("Create common area reservation")))])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }